import { useRoomClientStore } from "./store";
export var resetRoomClient = function() {
    return useRoomClientStore.getState().actions.reset();
};
export var updateStatus = function(status) {
    return useRoomClientStore.getState().actions.setStatus(status);
};
export var updateParticipant = function(participant) {
    return useRoomClientStore.getState().actions.setParticipant(participant);
};
export var updateContentStream = function(contentStream) {
    return useRoomClientStore.getState().actions.setContentStream(contentStream);
};
export var updateScheduledSession = function(scheduledSession) {
    return useRoomClientStore.getState().actions.setScheduledSession(scheduledSession);
};
export function getContentStream(select) {
    var contentStream = useRoomClientStore.getState().contentStream.data;
    return select ? select(contentStream) : contentStream;
}
export function getScheduledSession() {
    return useRoomClientStore.getState().scheduledSession;
}
export function getActiveSession() {
    var scheduledSession = getScheduledSession();
    return (scheduledSession === null || scheduledSession === void 0 ? void 0 : scheduledSession.started) ? scheduledSession : null;
}
export function getParticipant() {
    return useRoomClientStore.getState().participant;
}
