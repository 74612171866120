function _defineProperty(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
function _objectSpread(target) {
    for(var i = 1; i < arguments.length; i++){
        var source = arguments[i] != null ? arguments[i] : {};
        var ownKeys = Object.keys(source);
        if (typeof Object.getOwnPropertySymbols === "function") {
            ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function(sym) {
                return Object.getOwnPropertyDescriptor(source, sym).enumerable;
            }));
        }
        ownKeys.forEach(function(key) {
            _defineProperty(target, key, source[key]);
        });
    }
    return target;
}
import { UnhandledError } from "@internal/errors";
import { actionBgActive, actionTextActive, actionBg, actionBgDisabled, actionBgDisabledBase, actionTextDisabled, actionTextDisabledBase, actionBgHover, actionTextHover, actionText } from "../palette/semantic";
export var twButtonVariant = function(variant, hover, active, blend, disabled, rounded) {
    var classes = [
        actionText[variant],
        actionBgDisabled[variant],
        actionTextDisabled[variant],
        "focus-visible:outline-none",
        variant === "primary" ? "focus-visible:shadow-focusVisibleLg" : "focus-visible:shadow-focusVisible", 
    ];
    if (hover) {
        classes.push(actionBgHover[variant], actionTextHover[variant]);
    }
    if (active) {
        classes.push(actionBgActive[variant], actionTextActive[variant]);
    }
    if (!blend) {
        classes.push(actionBg[variant]);
    }
    if (rounded) {
        classes.push("!rounded-full");
    }
    if (disabled) {
        classes.push(actionBgDisabledBase[variant], actionTextDisabledBase[variant]);
    }
    return classes.join(" ");
};
export var twButtonLayout = function() {
    var size = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : "medium";
    switch(size){
        case "small":
            return "cta-small h-[2.125rem] min-h-[2.125rem] px-[0.53125rem] py-3 rounded-lg gap-1.5 [&_svg]:h-4 [&_svg]:w-4";
        case "medium":
            return "cta-large h-12 min-h-12 px-3 py-4 gap-2 [&_svg]:h-1.25 [&_svg]:h-[1.25rem] [&_svg]:w-[1.25rem]";
        default:
            throw new UnhandledError(size);
    }
};
export var twButtonRadius = function() {
    var size = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : "medium";
    return size === "small" ? "rounded-lg" : "rounded-xl";
};
export var twButtonTransition = function() {
    return "transition duration-[50ms] ease active:scale-[0.94] group-active:scale-[0.94]";
};
export var twButtonBase = function(param) {
    var variant = param.variant, fill = param.fill, rounded = param.rounded, _blend = param.blend, blend = _blend === void 0 ? false : _blend, disabled = param.disabled, _hover = param.hover, hover = _hover === void 0 ? true : _hover, _active = param.active, active = _active === void 0 ? true : _active;
    return "flex ".concat(fill ? "flex-1" : "", " truncate justify-center items-center overflow-hidden ").concat(twButtonVariant(variant, hover, active, blend, disabled, rounded));
};
var _variant;
export var twButton = function(props) {
    return "".concat(twButtonBase(_objectSpread({}, props, {
        variant: (_variant = props.variant) !== null && _variant !== void 0 ? _variant : "secondary"
    })), " ").concat(twButtonLayout(props.size), " ").concat(twButtonRadius(props.size), " ").concat(twButtonTransition());
};
export var twButtonText = function() {
    return "truncate";
};
