import { UnhandledError } from '@internal/errors';
import { isProduction } from '../utils/env';
import { STAFF_USER_IDS_PROD, STAFF_USER_IDS_DEV } from '@internal/config/whitelists/staff';

export const STAFF_USER_IDS = isProduction() ? STAFF_USER_IDS_PROD : STAFF_USER_IDS_DEV;

const CONTRACTOR_USER_IDS_PROD = new Set([
    '7c7f1adb-ee5d-4e8c-b422-3ab71445fdb3', // DJACK
    'c0a0cf80-3894-4ee4-83ce-cf3fd3e2872c', // dreamshake
    'a37872fa-43e9-4de8-b721-9c8d06745d60', // victorsvaliantyt
    'ec892a91-3c91-42d8-bd4a-1fde6f3a8b14', // Pat (Rob's video friend)
]);

const CONTRACTOR_USER_IDS_DEV = new Set([]);

export const CONTRACTOR_USER_IDS = isProduction()
    ? CONTRACTOR_USER_IDS_PROD
    : CONTRACTOR_USER_IDS_DEV;

export enum StaffLevel {
    Contractor = 0,
    Fulltime = 1,
}

export const hasStaffPermissions = (userId: string, level: StaffLevel) => {
    if (level === StaffLevel.Fulltime) {
        return STAFF_USER_IDS.has(userId);
    }

    if (level === StaffLevel.Contractor) {
        return STAFF_USER_IDS.has(userId) || CONTRACTOR_USER_IDS.has(userId);
    }

    throw new UnhandledError(level);
};
