export var RoomEntryType;
(function(RoomEntryType) {
    RoomEntryType["CreateRoom"] = "create";
    RoomEntryType["AddExistingRoom"] = "add existing room";
    RoomEntryType["DeepLink"] = "deep link";
    RoomEntryType["RoomList"] = "room list";
    RoomEntryType["Direct"] = "direct";
    RoomEntryType["Notification"] = "notification";
    RoomEntryType["Paste"] = "paste";
    RoomEntryType["DupeDisconnect"] = "dupe disconnect";
})(RoomEntryType || (RoomEntryType = {}));
export var ShadowEventType;
(function(ShadowEventType) {
    ShadowEventType["Identify"] = "i";
    ShadowEventType["Track"] = "t";
    ShadowEventType["Page"] = "p";
})(ShadowEventType || (ShadowEventType = {}));
export var TrackingContext;
(function(TrackingContext) {
    TrackingContext["Nav"] = "Nav";
    TrackingContext["RoomDropdown"] = "Room Dropdown";
    TrackingContext["LoginForm"] = "Login Form";
    TrackingContext["RoomNav"] = "Room Nav";
    TrackingContext["RoomControls"] = "Room Controls";
    TrackingContext["Queue"] = "Queue";
    TrackingContext["VideoOverlay"] = "Video Overlay";
    TrackingContext["PreviewExpired"] = "Stream Preview Expired";
    TrackingContext["MobileRoomDetails"] = "Mobile Room Details";
    TrackingContext["MobileInactiveRoom"] = "Mobile Inactive Room";
    TrackingContext["BlockedFeatureCTA"] = "Blocked Feature CTA";
    TrackingContext["SessionDetails"] = "Session Details";
    TrackingContext["RoomSchedule"] = "Room Schedule";
    TrackingContext["MobileRoomNav"] = "Mobile Room Nav";
    TrackingContext["GoLiveModal"] = "Go Live Modal";
    TrackingContext["BrowseStreamsModal"] = "Browse Streams Modal";
    TrackingContext["ScheduleSessionModal"] = "Schedule Session Modal";
    TrackingContext["ShareSessionModal"] = "Share Session Modal";
    TrackingContext["MobileStreamOverlay"] = "Mobile Stream Overlay";
    TrackingContext["RoomEmptyState"] = "Room Empty State";
    TrackingContext["RoomUpcomingSessions"] = "Room Upcoming Sessions";
    TrackingContext["TextChat"] = "Text Chat";
    TrackingContext["CommunityProfile"] = "Community Profile";
    TrackingContext["CommunityProfileChip"] = "Communitiy Profile Chip";
    TrackingContext["CommunitySettings"] = "Community Settings";
    TrackingContext["NetworkIndicator"] = "Network Indicator";
    TrackingContext["Error500"] = "500 Error Page";
    TrackingContext["Error404"] = "404 Error Page";
    TrackingContext["Error401"] = "401 Error Page";
    TrackingContext["JoinCommunity"] = "Join Community";
    TrackingContext["LeaveCommunity"] = "Leave Community";
    TrackingContext["Stage"] = "Stage";
})(TrackingContext || (TrackingContext = {}));
