function _defineProperty(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
function _objectSpread(target) {
    for(var i = 1; i < arguments.length; i++){
        var source = arguments[i] != null ? arguments[i] : {};
        var ownKeys = Object.keys(source);
        if (typeof Object.getOwnPropertySymbols === "function") {
            ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function(sym) {
                return Object.getOwnPropertyDescriptor(source, sym).enumerable;
            }));
        }
        ownKeys.forEach(function(key) {
            _defineProperty(target, key, source[key]);
        });
    }
    return target;
}
import create from "zustand";
var initialState = {
    status: "disconnected",
    participant: null,
    scheduledSession: null,
    contentStream: {
        data: {
            relay: null,
            webSocketURL: null,
            accessSummary: {
                pending: true,
                bypass: false,
                denialReason: null,
                eligiblePlatforms: []
            },
            previewTimer: {
                duration: 0,
                remaining: -1
            }
        },
        lastUpdatedAt: 0
    }
};
export var useRoomClientStore = create(function(set) {
    return _objectSpread({}, initialState, {
        actions: {
            setStatus: function(status) {
                set({
                    status: status
                });
            },
            setParticipant: function(participant) {
                set({
                    participant: participant
                });
            },
            setContentStream: function(data) {
                set({
                    contentStream: {
                        data: data,
                        lastUpdatedAt: Date.now()
                    }
                });
            },
            setScheduledSession: function(scheduledSession) {
                set({
                    scheduledSession: scheduledSession
                });
            },
            reset: function() {
                return set(initialState);
            }
        }
    });
});
