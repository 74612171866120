import { useRoomClientStore } from "./store";
export function useContentStream(select) {
    return useRoomClientStore(function(state) {
        return select ? select(state.contentStream.data) : state.contentStream.data;
    });
}
export function usePreviewTimer() {
    return useRoomClientStore(function(state) {
        var lastPreviewTimer = state.contentStream.data.previewTimer;
        var remaining = lastPreviewTimer.remaining, duration = lastPreviewTimer.duration;
        if (remaining <= 0) {
            return lastPreviewTimer;
        }
        var timeRemaining = remaining - (Date.now() - state.contentStream.lastUpdatedAt);
        if (timeRemaining > 0) {
            return {
                remaining: timeRemaining,
                duration: duration
            };
        }
        return {
            remaining: 0,
            duration: duration
        };
    });
}
export function useParticipant() {
    return useRoomClientStore(function(state) {
        return state.participant;
    });
}
export function useRoomClientStatus() {
    return useRoomClientStore(function(state) {
        return state.status;
    });
}
export function useScheduledSession() {
    return useRoomClientStore(function(state) {
        return state.scheduledSession;
    });
}
export function useActiveSession() {
    return useRoomClientStore(function(state) {
        var ref;
        return ((ref = state.scheduledSession) === null || ref === void 0 ? void 0 : ref.started) ? state.scheduledSession : null;
    });
}
