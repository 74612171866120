function _arrayLikeToArray(arr, len) {
    if (len == null || len > arr.length) len = arr.length;
    for(var i = 0, arr2 = new Array(len); i < len; i++)arr2[i] = arr[i];
    return arr2;
}
function _arrayWithoutHoles(arr) {
    if (Array.isArray(arr)) return _arrayLikeToArray(arr);
}
function _defineProperty(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
function _iterableToArray(iter) {
    if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter);
}
function _nonIterableSpread() {
    throw new TypeError("Invalid attempt to spread non-iterable instance.\\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");
}
function _objectSpread(target) {
    for(var i = 1; i < arguments.length; i++){
        var source = arguments[i] != null ? arguments[i] : {};
        var ownKeys = Object.keys(source);
        if (typeof Object.getOwnPropertySymbols === "function") {
            ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function(sym) {
                return Object.getOwnPropertyDescriptor(source, sym).enumerable;
            }));
        }
        ownKeys.forEach(function(key) {
            _defineProperty(target, key, source[key]);
        });
    }
    return target;
}
function _toConsumableArray(arr) {
    return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread();
}
function _unsupportedIterableToArray(o, minLen) {
    if (!o) return;
    if (typeof o === "string") return _arrayLikeToArray(o, minLen);
    var n = Object.prototype.toString.call(o).slice(8, -1);
    if (n === "Object" && o.constructor) n = o.constructor.name;
    if (n === "Map" || n === "Set") return Array.from(n);
    if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen);
}
import { acceptCreatorTermsPOST, userDELETE, userGET, userLoginPOST, userNotificationsOptOutDELETE, userNotificationsOptOutPUT, userNotificationsOptOutsGET, userPOST } from "@internal/api/user";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { QueryKey } from "./queryKeys";
import { useApiContext } from "./context";
import { QueryKeyFactory } from "./QueryKeyFactory";
export var useCurrentUser = function(options) {
    var ref = useApiContext(), token = ref.token, host = ref.host;
    return useQuery([
        QueryKey.User,
        token
    ], function() {
        return userGET(token, host);
    }, options);
};
export var useUserLogin = function(mutationOptions) {
    var host = useApiContext().host;
    return useMutation(function(request) {
        return userLoginPOST(request, host);
    }, _objectSpread({}, mutationOptions, {
        onSuccess: function(data, variables, context) {
            var ref;
            mutationOptions === null || mutationOptions === void 0 ? void 0 : (ref = mutationOptions.onSuccess) === null || ref === void 0 ? void 0 : ref.call(mutationOptions, data, variables, context);
        }
    }));
};
export var useUserUpdate = function(options) {
    var ref = useApiContext(), token = ref.token, host = ref.host;
    return useMutation(function(data) {
        return userPOST(_objectSpread({}, data, {
            accessToken: token
        }), host);
    }, options);
};
export var useUserDelete = function(options) {
    var ref = useApiContext(), token = ref.token, host = ref.host;
    return useMutation(function() {
        return userDELETE(token, host);
    }, options);
};
export var useNotificationsOptOuts = function(request, options) {
    var ref = useApiContext(), token = ref.token, host = ref.host;
    var queryClient = useQueryClient();
    var queryKey = QueryKeyFactory.notificationsOptOuts(request);
    var query = useQuery(queryKey, function() {
        return userNotificationsOptOutsGET(token, request, host);
    }, options);
    var createMutation = useMutation(function(type) {
        return userNotificationsOptOutPUT(token, {
            channel: request.channel,
            notification: type,
            roomID: request.roomID
        }, host);
    }, {
        onMutate: function(notificationType) {
            queryClient.setQueryData(queryKey, _toConsumableArray(query.data).concat([
                {
                    // id and user ID are mocked for optimistic updates. They're refreshed with
                    // actual data after the mutation succeeds.
                    id: null,
                    userID: "",
                    roomID: request.roomID,
                    notification: notificationType,
                    channel: request.channel
                }, 
            ]));
            return {
                snapshot: query.data
            };
        },
        onError: function(_err, _request, context) {
            queryClient.setQueryData(queryKey, context.snapshot);
        },
        onSettled: function() {
            queryClient.invalidateQueries(queryKey);
        }
    });
    var deleteMutation = useMutation(function(id) {
        return userNotificationsOptOutDELETE(token, {
            id: id
        }, host);
    }, {
        onMutate: function(optOutId) {
            queryClient.setQueryData(queryKey, query.data.filter(function(optOut) {
                return optOut.id !== optOutId;
            }));
            return {
                snapshot: query.data
            };
        },
        onError: function(_err, _request, context) {
            queryClient.setQueryData(queryKey, context.snapshot);
        },
        onSettled: function() {
            queryClient.invalidateQueries(queryKey);
        }
    });
    var toggleSetting = function(type) {
        // Components that use this hook should present a loading or disabled state until opt outs
        // have been loaded.
        if (!query.isSuccess) {
            return;
        }
        var existingOptOut = query.data.find(function(optOut) {
            return optOut.notification === type;
        });
        if (existingOptOut) {
            //TODO delete opt outs by sending room ID and notification type instead of the opt out
            // id. That should avoid this scenario where we can't delete a notification before the
            // request to create has settled.
            if (!existingOptOut.id) {
                return;
            }
            deleteMutation.mutate(existingOptOut.id);
        } else {
            createMutation.mutate(type);
        }
    };
    return _objectSpread({}, query, {
        toggleSetting: toggleSetting
    });
};
export var useAcceptCreatorTerms = function(options) {
    var ref = useApiContext(), token = ref.token, host = ref.host;
    var queryClient = useQueryClient();
    return useMutation(function(revision) {
        return acceptCreatorTermsPOST({
            accessToken: token,
            revision: revision
        }, host);
    }, _objectSpread({}, options, {
        onSuccess: function(data, variables, context) {
            queryClient.invalidateQueries([
                QueryKey.User
            ]);
            options === null || options === void 0 ? void 0 : options.onSuccess(data, variables, context);
        }
    }));
};
