import { ReactNode, useMemo } from 'react';
import ReactDOM from 'react-dom';

export const OVERLAY_ROOT_BOTTOM_ID = 'overlay-root-bottom';
export const OVERLAY_ROOT_TOP_ID = 'overlay-root-top';

interface OverlayPortalProps {
    position?: 'top' | 'bottom';
    children: ReactNode;
}

const OverlayPortal = ({ position = 'bottom', children }: OverlayPortalProps) => {
    const root = useMemo(() => {
        if (typeof window === 'undefined') {
            return null;
        }

        return document.body.querySelector(
            `#${position === 'top' ? OVERLAY_ROOT_TOP_ID : OVERLAY_ROOT_BOTTOM_ID}`
        );
    }, [position]);

    if (!root) {
        return null;
    }

    return ReactDOM.createPortal(children, root);
};

export default OverlayPortal;
